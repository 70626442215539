export * from "./localStorageActions";
export * from "./questions";

export const extractFetchError = async (error) => {
	try {
		const { status } = error;
		const data = await error.json();
		return { ...data, status };
	} catch (e) {
		throw e;
	}
};

export function isFileExtAllowed(fileExt = "") {
	return ["pdf", "docx", "doc"].includes(fileExt.toLowerCase());
}
