import { useEffect, useRef, useState } from "react";
import { Alert, message, Button, Spin } from "antd";

import UploadButton from "../UploadBtn";
import UploadHeader from "../UploadHeader";
import Questions from "../Questions";
import style from "./style.module.css";
import { useLocalStorage } from "../../Context/LocalStorageContext";
import { extractFetchError, isFileExtAllowed } from "../../Utils";
import { CloseCircleOutlined, LoadingOutlined } from "@ant-design/icons";

const UploadCritique = ({ customizations, setReviewLink, clientId, savedQuestions, reviewLink }) => {
	const {
		widgetBottomBgColor,
		widgetBottomTextColor,
		iconBgColor,
		widgetActionButtonBgColor,
		widgetActionButtonTextColor,
		widgetActionButtonBorderColor,
		iconTextColor,
	} = customizations;

	const [uploading, setUploading] = useState(false);
	const [fileData, setFileData] = useState(null);
	const [error, setError] = useState("");
	const [fileInfo, setFileInfo] = useState({
		fullName: "",
		fileNameNoExt: "",
		fileExt: "",
	});

	const fileInputRef = useRef();

	const { setCritiqueResponseData: setCritiqueData, critiqueResponseData: critiqueData } = useLocalStorage();

	const protocol = window.location.protocol;

	const fileName =
		fileInfo.fileNameNoExt.length > 10
			? `${fileInfo.fileNameNoExt.slice(0, 10)}...${fileInfo.fileExt}`
			: fileInfo.fullName;

	const beforeUpload = (file) => {
		const fileNameArr = file.name.split(".");
		const fileExt = fileNameArr[fileNameArr.length - 1];

		const isFileTypeAllowed = isFileExtAllowed(fileExt);
		if (!isFileTypeAllowed) {
			message.error("You can only upload pdf, doc and docx files!");
		}

		setFileInfo({
			fullName: file.name,
			fileNameNoExt: fileNameArr[0],
			fileExt,
		});
		return isFileTypeAllowed;
	};

	const onFileChange = ({ target }) => {
		const { files } = target;
		if (files.length) {
			const [file] = files;
			const isValid = beforeUpload(file);
			if (isValid) {
				setFileData(file);
			}
		}
	};

	const handleUpload = async () => {
		try {
			setUploading(true);
			setError(null);
			const formData = new FormData();
			formData.append("file", fileData);

			const response = await fetch(
				`${protocol}//${process.env.REACT_APP_API_DOMAIN}/v2/clients/${clientId}/critiques-public`,
				{
					method: "POST",
					body: formData,
				}
			);

			if (response.ok) {
				const data = await response.json();
				setReviewLink(data.reviewLink);
				setCritiqueData({
					...data.critiqueData,
					reviewLink: data.reviewLink,
				});
			} else {
				const error = await extractFetchError(response);
				if (error?.error?.includes("cannot detect work experience")) {
					setError(
						"We couldn't find any work experience details in the uploaded document. Please make sure your resume includes your work history for accurate processing."
					);
					return;
				} else if (error?.error?.includes("unable to download")) {
					setError(
						"We're having trouble downloading and converting your resume. Could you please ensure that we have the right permissions to access it?"
					);
					return;
				} else if (error?.error?.includes("is too short")) {
					setError(
						"It seems that the content of the uploaded document is quite brief. If it's a scan or image, please provide a text-based version of your resume for better processing."
					);
					return;
				} else if (error?.error?.includes("too long")) {
					setError(
						"The document you uploaded appears to be quite lengthy and may not be a standard resume. Please upload a concise version that focuses on your professional background."
					);
					return;
				} else if (error?.error?.includes("contains code")) {
					setError(
						"It looks like the uploaded file contains code. For accurate processing, please ensure that you're uploading a text-based resume without code elements."
					);
					return;
				}
				setError("Oops! Something went wrong while processing your resume. Could you try uploading it again?");
			}
		} catch (e) {
			setError(e.message);
		} finally {
			setUploading(false);
		}
	};

	const extraPadding = !reviewLink ? style["extra-padding"] : "";

	const handleFileUpload = () => {
		if (fileInputRef?.current) {
			fileInputRef.current.click();
		}
	};

	useEffect(() => {
		setError(null);
	}, [fileData]);

	return (
		<>
			<UploadHeader customizations={customizations} />
			<div
				className={`${style["upload-container"]} ${extraPadding}`}
				style={{
					backgroundColor: widgetBottomBgColor,
				}}
			>
				{Object.keys(critiqueData).length || reviewLink ? (
					<Questions
						reviewLink={reviewLink}
						savedQuestions={savedQuestions}
						setReviewLink={setReviewLink}
						{...critiqueData}
						clientId={clientId}
						customization={{
							widgetBottomTextColor,
							widgetActionButtonBgColor,
							widgetActionButtonTextColor,
							widgetActionButtonBorderColor,
						}}
					/>
				) : (
					<>
						<div className={style["upload-btn-container"]}>
							<UploadButton
								customization={{
									iconBgColor,
									iconTextColor,
								}}
								onClick={handleFileUpload}
								disabled={uploading}
								fileData={fileData}
							/>
							{!fileData ? (
								<p className={style["upload-resume"]} style={{ color: widgetBottomTextColor }}>
									Upload Resume
								</p>
							) : (
								<div className={style["progress-container"]}>
									<div>
										<div className={style["file-name-container"]}>
											<button
												disabled={uploading}
												onClick={() => setFileData(null)}
												className={style["delete-file-icon"]}
												style={{ color: iconTextColor }}
											>
												<CloseCircleOutlined
													style={{
														color: widgetBottomTextColor || "#FFFFFF",
														fontSize: "1rem",
													}}
												/>
											</button>

											<p className={style["file-name"]} style={{ color: widgetBottomTextColor }}>
												{fileName}
											</p>
										</div>

										{fileData && !error && (
											<Button
												disabled={uploading}
												type="primary"
												className={style["upload-progress"]}
												onClick={handleUpload}
												style={{
													background: widgetActionButtonBgColor,
													color: widgetActionButtonTextColor,
													borderColor: widgetActionButtonBorderColor,
												}}
											>
												{uploading ? (
													<Spin
														indicator={
															<LoadingOutlined
																style={{
																	fontSize: "1rem",
																	color: widgetActionButtonTextColor,
																}}
															/>
														}
													/>
												) : (
													"Upload"
												)}
											</Button>
										)}
									</div>
								</div>
							)}
						</div>
						<input
							accept={".pdf, .docx, .doc"}
							ref={fileInputRef}
							type="file"
							name="file"
							onChange={onFileChange}
							className={style["file-upload-input"]}
						/>
					</>
				)}
				{error && <Alert type="error" message={error} style={{ color: widgetBottomTextColor }} />}
			</div>
		</>
	);
};

export default UploadCritique;
