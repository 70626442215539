import { useEffect, useRef, useState } from "react";
import { Alert, message, Button, Spin } from "antd";
import clsx from "clsx";
import Questions from "../Questions";
import styles from "./widgets.module.css";
import { useLocalStorage } from "../../Context/LocalStorageContext";
import { extractFetchError, isFileExtAllowed } from "../../Utils";
import { CloseCircleOutlined, LoadingOutlined } from "@ant-design/icons";

const CenteredWidget = ({ customizations, setReviewLink, clientId, savedQuestions, reviewLink }) => {
	const {
		widgetBottomBgColor,
		widgetBottomTextColor,
		widgetTopBgColor,
		widgetTopTextColor,
		iconBgColor,
		widgetActionButtonBgColor,
		widgetActionButtonTextColor,
		widgetActionButtonBorderColor,
		iconTextColor,
		widgetTopHeaderTextColor,
		widgetTopSubheadingTextColor,
		widgetSupportedFileTextColor,
	} = customizations;

	const [uploading, setUploading] = useState(false);
	const [fileData, setFileData] = useState(null);
	const [error, setError] = useState("");
	const [fileInfo, setFileInfo] = useState({
		fullName: "",
		fileNameNoExt: "",
		fileExt: "",
	});

	const fileInputRef = useRef();

	const { setCritiqueResponseData: setCritiqueData, critiqueResponseData: critiqueData } = useLocalStorage();

	const protocol = window.location.protocol;

	const fileName =
		fileInfo.fileNameNoExt.length > 10
			? `${fileInfo.fileNameNoExt.slice(0, 10)}...${fileInfo.fileExt}`
			: fileInfo.fullName;

	const beforeUpload = (file) => {
		const fileNameArr = file.name.split(".");
		const fileExt = fileNameArr[fileNameArr.length - 1];

		const isFileTypeAllowed = isFileExtAllowed(fileExt);
		if (!isFileTypeAllowed) {
			message.error("You can only upload pdf, doc and docx files!");
		}

		setFileInfo({
			fullName: file.name,
			fileNameNoExt: fileNameArr[0],
			fileExt,
		});
		return isFileTypeAllowed;
	};

	const onFileChange = ({ target }) => {
		const { files } = target;
		if (files.length) {
			const [file] = files;
			const isValid = beforeUpload(file);
			if (isValid) {
				setFileData(file);
			}
		}
	};

	const handleUpload = async () => {
		try {
			setUploading(true);
			setError(null);
			const formData = new FormData();
			formData.append("file", fileData);

			const response = await fetch(
				`${protocol}//${process.env.REACT_APP_API_DOMAIN}/v2/clients/${clientId}/critiques-public`,
				{
					method: "POST",
					body: formData,
				}
			);

			if (response.ok) {
				const data = await response.json();
				setReviewLink(data.reviewLink);
				setCritiqueData({
					...data.critiqueData,
					reviewLink: data.reviewLink,
				});
			} else {
				const error = await extractFetchError(response);
				if (error?.error?.includes("cannot detect work experience")) {
					setError(
						"We couldn't find any work experience details in the uploaded document. Please make sure your resume includes your work history for accurate processing."
					);
					return;
				} else if (error?.error?.includes("unable to download")) {
					setError(
						"We're having trouble downloading and converting your resume. Could you please ensure that we have the right permissions to access it?"
					);
					return;
				} else if (error?.error?.includes("is too short")) {
					setError(
						"It seems that the content of the uploaded document is quite brief. If it's a scan or image, please provide a text-based version of your resume for better processing."
					);
					return;
				} else if (error?.error?.includes("too long")) {
					setError(
						"The document you uploaded appears to be quite lengthy and may not be a standard resume. Please upload a concise version that focuses on your professional background."
					);
					return;
				} else if (error?.error?.includes("contains code")) {
					setError(
						"It looks like the uploaded file contains code. For accurate processing, please ensure that you're uploading a text-based resume without code elements."
					);
					return;
				}
				setError("Oops! Something went wrong while processing your resume. Could you try uploading it again?");
			}
		} catch (e) {
			setError(e.message);
		} finally {
			setUploading(false);
		}
	};

	const extraPadding = fileData || !reviewLink ? styles["extra-padding"] : "";

	const handleFileUpload = () => {
		if (fileInputRef?.current) {
			fileInputRef.current.click();
		}
	};

	useEffect(() => {
		setError(null);
	}, [fileData]);

	return (
		<>
			<div className={styles["widget__body__centered"]}>
				<div
					className={styles["widget__header"]}
					style={{ color: widgetTopHeaderTextColor, backgroundColor: widgetTopBgColor }}
				>
					Expert Resume Review
				</div>
				<div
					className={styles["widget__top__section"]}
					style={{ color: widgetTopTextColor, backgroundColor: widgetTopBgColor }}
				>
					<h4 className={styles["widget__heading"]}>
						Get your FREE AI
						<br /> Resume Expert Review
					</h4>
					<p className={styles["widget__subheading"]} style={{ color: widgetTopSubheadingTextColor }}>
						Receive instant feedback on how your resume is perceived by potential employers.
					</p>
				</div>

				<div
					className={`${extraPadding}`}
					style={{
						backgroundColor: widgetBottomBgColor,
					}}
				>
					{Object.keys(critiqueData).length || reviewLink ? (
						<Questions
							modalIsCentered
							reviewLink={reviewLink}
							savedQuestions={savedQuestions}
							setReviewLink={setReviewLink}
							{...critiqueData}
							clientId={clientId}
							customization={{
								widgetBottomTextColor,
								widgetActionButtonBgColor,
								widgetActionButtonTextColor,
								widgetActionButtonBorderColor,
							}}
						/>
					) : (
						<div
							className={styles["widget__bottom__section"]}
							style={{ backgroundColor: widgetBottomBgColor, color: widgetBottomTextColor }}
						>
							<div className={styles["upload__section"]}>
								<p className={styles["upload__text"]}>Upload Resume</p>
								<div
									className={clsx({
										[styles["file__upload__button"]]: true,
										[styles["file__upload__button-uploading"]]: uploading,
									})}
									style={{ background: iconBgColor, color: iconTextColor }}
									onClick={handleFileUpload}
								>
									Click here or drag file to upload
								</div>
								<input
									disabled={uploading}
									accept={".pdf, .docx, .doc"}
									ref={fileInputRef}
									type="file"
									name="file"
									onChange={onFileChange}
									className={styles["file-upload-input"]}
								/>
								<p
									className={styles["supported__file"]}
									style={{ color: widgetSupportedFileTextColor }}
								>
									Supported format: .pdf, .docx, .doc
								</p>
								{fileData && (
									<div className={styles["progress-container"]}>
										<div>
											<div className={styles["file-name-container"]}>
												<button
													disabled={uploading}
													onClick={() => setFileData(null)}
													className={styles["delete-file-icon"]}
													style={{ color: iconTextColor }}
												>
													<CloseCircleOutlined
														style={{
															color: widgetBottomTextColor || "#FFFFFF",
															fontSize: "1rem",
														}}
													/>
												</button>

												<p
													className={styles["file-name"]}
													style={{ color: widgetBottomTextColor }}
												>
													{fileName}
												</p>
											</div>

											{fileData && !error && (
												<Button
													disabled={uploading}
													type="primary"
													className={styles["upload-progress"]}
													onClick={handleUpload}
													style={{
														background: widgetActionButtonBgColor,
														color: widgetActionButtonTextColor,
														borderColor: widgetActionButtonBorderColor,
													}}
												>
													{uploading ? (
														<Spin
															indicator={
																<LoadingOutlined
																	style={{
																		fontSize: "1rem",
																		color: widgetActionButtonTextColor,
																	}}
																/>
															}
														/>
													) : (
														"Upload"
													)}
												</Button>
											)}
										</div>
									</div>
								)}
							</div>
						</div>
					)}
					{error && <Alert type="error" message={error} style={{ color: widgetBottomTextColor, marginInline: "1rem" }} />}
				</div>
			</div>
		</>
	);
};

export default CenteredWidget;
